import React from "react";
import Topbar from "../../Components/topbar/Topbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import StudentTable from "../../Components/StudentTable/StudentTable";
import "./StudentList.css";

export default function StudentList() {
  return (
    <div className="StudentList">
      <Topbar />
      <div className="SL">
        <Sidebar />
        <StudentTable />
      </div>
    </div>
  );
}
