import { useState, useEffect } from "react";
import { addReferee, getCompanyList } from "../../api/api";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";

import { Discard } from "../DiscardMsg/Discard";
import { useNavigatingAway } from "../../hooks/useNavigatingAway";

const frontend_url = process.env.FRONTEND_URL;

function Referee() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [workDuration, setWorkDuration] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  useEffect(() => {
    getCompanyList((res) => {
      console.log(res.data);
      setCompanyList(res.data);
    });
  }, []);

  const formHandler = (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
      work_duration: workDuration,
      company: companyName,
    };

    addReferee(data, (res) => {
      console.log(res);
      if (res.status === 201) {
        console.log("Referee Created Successfully");

        toast.success("Referee Created Successfully, Redirecting...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        setCanShowDialogLeavingPage(false);
        setTimeout(() => {
          window.location.replace("https://jrms.qatekinternal.com/Refereelist");
        }, 3000);
      } else {
        console.log("Failed to create referee", res);

        toast.error("Failed to create referee", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };

  return (
    <>
      <Topbar />
      <div className="SL">
        <Discard
          showDialog={showDialogLeavingPage}
          setShowDialog={setCanShowDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Sidebar />
        <div className="StudentTable">
          <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div className="row">
                <div className="col">
                  <div class="d-flex justify-content-center py-4 title">
                    <h1>Add Referee </h1>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="pt-4 pb-2"></div>

                      <form
                        class="row g-3 needs-validation"
                        novalidate
                        onSubmit={formHandler}
                      >
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Referee's First Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter your name!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Referee's Last Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setLastName(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter your name!
                          </div>
                        </div>

                        <div class="col-6">
                          <input
                            type="phone"
                            name="name"
                            placeholder="Phone Number"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setPhone(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter your Phone Number!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="email"
                            name="name"
                            placeholder="Email"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter your Email Address!
                          </div>
                        </div>

                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Work Duration"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setWorkDuration(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter your Work Duration!
                          </div>
                        </div>
                        <div className="col-6">
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            onChange={(event, newValue) => {
                              setCompanyName(newValue);
                            }}
                            options={companyList.map((option) => option.name)}
                            renderInput={(params) => (
                              <TextField
                                onChange={(e) => setCompanyName(e.target.value)}
                                {...params}
                                label="Company's Name"
                              />
                            )}
                          />
                        </div>

                        <div class="col-12 d-flex justify-content-center">
                          <ToastContainer />
                        </div>

                        <div class="col-12 d-flex justify-content-center">
                          <button
                            class="btn btn-primary 
                       "
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Referee;
