import "../Login/Login.css";
import { useState } from "react";
import { otpVerification } from "../../api/api";

const frontend_url = process.env.FRONTEND_URL;

function Otp() {
  const [code, setCode] = useState("");

  const formHandler = (e) => {
    e.preventDefault();
    const email = localStorage.getItem("email");

    const data = {
      email: email,
      otp: code,
    };

    otpVerification(data, (res) => {
      if (res.status === 200) {
        if (res.code === 200) {
          console.log("Code Found");
          window.location.replace(
            "https://jrms.qatekinternal.com/password-confirmation"
          );
        } else {
          console.log("Code not found");
        }
      } else {
        console.log("Failed", res);
      }
    });
  };

  return (
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div className="container">
        <div className="row">
          <div className="col">
            <div class="d-flex justify-content-center py-4 title">
              <h1>Job Reference Management System</h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="card mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    Enter security code
                  </h5>
                  <p className="text-center small">
                    Please check your email for a message with your code.
                  </p>
                </div>
                <form
                  className="row g-3 needs-validation"
                  noValidate
                  onSubmit={formHandler}
                >
                  <div className="col-12">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        name="username"
                        placeholder="Enter code"
                        className="form-control"
                        id="yourUsername"
                        onChange={(e) => setCode(e.target.value)}
                        required
                      />

                      <div className="invalid-feedback">
                        Please enter your code.
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 button"
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>

                  {/* <div className="col-12 text-center">
                    <p className="small mb-0">
                      <button type="button" class="btn btn-primary">
                        <a href="/Signup" className="button">
                          Create an account
                        </a>
                      </button>
                    </p>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Otp;
