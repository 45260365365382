import { useState } from "react";
import { Navigate } from "react-router";

const PrivateRoute = ({ children }) => {
  const [jwt, setJwt] = useState(localStorage.getItem("token"));

  return jwt ? children : <Navigate to="/" />;
};

export default PrivateRoute;
