import { useState, useEffect } from "react";
import { login, getRole } from "../../api/api";
import logo from "../../assets/logo.png";
import "./Login.css";

const frontend_url = process.env.FRONTEND_URL;

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function Password() {
    var x = document.getElementById("yourPassword");
    var y = document.getElementById("hide1");
    var z = document.getElementById("hide2");

    if (x.type === "password") {
      x.type = "text";
      y.style.display = "block";
      z.style.display = "none";
    } else {
      x.type = "password";
      y.style.display = "none";
      z.style.display = "block";
    }
  }

  const formHandler = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password: password,
    };
    login(data, (response) => {
      if (response.token) {
        localStorage.clear();
        localStorage.setItem("token", response.token);
        localStorage.setItem("email", username);

        window.location.replace("https://jrms.qatekinternal.com/dashboard");
      } else {
        setUsername("");
        setPassword("");
        localStorage.clear();
        // setErrors(true);
      }
    });
  };
  return (
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <div className="d-flex justify-content-center py-4 title">
              <img className="logostyle" src={logo} alt="logo" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div class="d-flex justify-content-center py-4 title">
              <h1>Job Reference Management System</h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="card mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    Login to Your Account
                  </h5>
                  <p className="text-center small">
                    Enter your username &amp; password to login
                  </p>
                </div>
                <form
                  className="row g-3 needs-validation"
                  noValidate
                  onSubmit={formHandler}
                >
                  <div className="col-12">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        name="username"
                        placeholder="Email Address"
                        className="form-control"
                        id="yourUsername"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />

                      <div className="invalid-feedback">
                        Please enter your username.
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div class="input-group">
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="form-control"
                        id="yourPassword"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span className="eye input-group-text" onClick={Password}>
                        <i id="hide1" class="fas fa-eye"></i>
                        <i id="hide2" class="fas fa-eye-slash"></i>
                      </span>
                    </div>

                    <div className="invalid-feedback">
                      Please enter your password!
                    </div>
                  </div>

                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">
                      <a href="/StudentList" className="button">
                        Login
                      </a>
                    </button>
                  </div>
                  <div className="col-12 text-center">
                    <p className="small mb-0">
                      Forgot Password?
                      <a className="resetPassword" href="reset-password">
                        Reset Password
                      </a>
                    </p>
                  </div>
                  <div className="col-12 text-center">
                    <p className="small mb-0">
                      <button type="button" class="btn btn-primary">
                        <a href="/Signup" className="button">
                          Create an account
                        </a>
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
