import { React, useEffect, useState } from "react";
// import React from "react";

import { getCompanyList, getRecruitment, getStudent } from "../../api/api";
import "../StudentTable/StudentTable.css";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import MaterialTable from "material-table";
import tableIcons from "../tableIcon/tableIcon";
import { ToastContainer, toast } from "react-toastify";

const base_url = process.env.REACT_APP_SERVER_URL;

export default function RecruitmentTable() {
  const [recruitment, setRecruitment] = useState([]);
  const [company, setCompany] = useState([]);
  const [candidate, setCandidate] = useState([]);

  const getRecruitmentData = async () => {
    getRecruitment((res) => {
      if (res.status === 200) {
        console.log("recruitment fetched Successfully");

        console.log(res);

        const temp = res.data;
        var recruitmentList = [];
        temp.map((recruiter) => {
          var item = {
            id: recruiter.id,
            first_name: recruiter.first_name,
            last_name: recruiter.last_name,
            email: recruiter.email,
            phone: recruiter.phone,
            candidate: recruiter.candidate.email,
            company: recruiter.company.name,
          };
          recruitmentList.push(item);
        });
        setRecruitment(recruitmentList);
        console.log(recruitment);
      } else {
        console.log("Failed to fetch recruitment", res);
      }
    });
  };

  useEffect(() => {
    getRecruitmentData();
    getCompanyList((res) => {
      console.log(res.data);
      setCompany(res.data);
    });

    getStudent((res) => {
      console.log(res.data);
      setCandidate(res.data);
    });
  }, []);

  const companyOptions = {};
  const candidateOptions = {};

  candidate.map((client) => {
    const { email } = client;
    candidateOptions[email] = email;
  });

  company.map((client) => {
    const { name } = client;
    companyOptions[name] = name;
  });

  const columns = [
    { title: "Recruiter's First Name", field: "first_name" },
    { title: "Recruiter's  Last Name", field: "last_name" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phone" },
    { title: "Candidate Email", field: "candidate", lookup: candidateOptions },
    { title: "Company", field: "company", lookup: companyOptions },
  ];

  return (
    <div className="StudentTable">
      <MaterialTable
        title="Recruiting Company List"
        data={recruitment}
        icons={tableIcons}
        columns={columns}
        editable={{
          // onRowAdd: (newRow) => new Promise((resolve, reject) => {
          //   const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
          //   setTimeout(() => {
          //     setData(updatedRows)
          //     resolve()
          //   }, 2000)
          // }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              console.log(oldData.id);
              fetch(base_url + "/api/recruitment" + "/" + oldData.id + "/", {
                method: "DELETE",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  getRecruitmentData();
                  toast.success("Deleted Successfully", {
                    position: toast.POSITION.bottom_right,
                  });
                  resolve();
                });
              // const updatedRows = [...data]
              // updatedRows.splice(index, 1)
              // setTimeout(() => {
              //   setData(updatedRows)
              //   resolve()
              // }, 2000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              console.log(newData);
              console.log(oldData);
              //Backend call
              fetch(base_url + "/api/recruitment" + "/" + oldData.id + "/", {
                method: "PUT",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },

                body: JSON.stringify(newData),
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  getRecruitmentData();
                  toast.success("Updated Successfully", {
                    position: toast.POSITION.bottom_right,
                  });
                  resolve();
                });
            }),
        }}
        options={{
          headerStyle: {
            backgroundColor: "#d8f0ff",
            fontWeight: 600,
            pageSize: 15,
          },
          sorting: true,
          actionsColumnIndex: -1,
          addRowPosition: "first",
          pageSize: 15,
          exportButton: true,
        }}
      />
      <ToastContainer />
    </div>
  );
}
