import { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Topbar from "../topbar/Topbar";
import { getUser } from "../../api/api";

import CustomCell from "./CustomCell";

import CssBaseline from "@material-ui/core/CssBaseline";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./UserApproval.css";

function Approval() {
  const headers = ["Name", "Email", "Role", "Status", "Action", "Save"];

  const [users, setUsers] = useState([]);

  getUser((res) => {
    if (users.length !== 0) return;

    if (res.status === 200) {
      console.log("User fetched Successfully");

      console.log(res);

      const temp = res.data;
      var userList = [];
      temp.map((user) => {
        var item = [user.name, user.email, user.role, user.verified];
        userList.push(item);
      });
      setUsers(userList);
      console.log(users);
    } else {
      console.log("Failed to fetch users", res);
    }
  });

  return (
    <>
      <Topbar />

      <div className="SL">
        <Sidebar />

        <MaUTable className="approval-table">
          <TableHead>
            <TableRow className="table-header">
              {headers.map((header) => (
                <TableCell className="header-cell">{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow>
                <TableCell>{row[0]}</TableCell>
                <TableCell>{row[1]}</TableCell>
                <TableCell>{row[2]}</TableCell>
                <TableCell>{row[3]}</TableCell>
                <CustomCell email={row[1]} status={row[3]} />
              </TableRow>
            ))}
          </TableBody>
        </MaUTable>
      </div>
    </>
  );
}

export default Approval;
