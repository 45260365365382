import { React, useState, useEffect } from 'react';
import './StudentTable.css';
import { getStudent, getCompanyList } from '../../api/api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Editbutton from './Editbutton';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import MUIDataTable from 'mui-datatables';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
import tableIcons from '../tableIcon/tableIcon';
import { ToastContainer, toast } from 'react-toastify';

export default function StudentTable() {
  const [value, setValue] = useState(new Date());

  // const [student, setStudent] = useState([]);

  // getStudent((res) => {
  //   if (student.length !== 0) return;

  //   if (res.status === 200) {
  //     console.log("Candidate fetched Successfully");

  //     console.log(res);
  //      setStudent(res.data)

  //   }

  //   else {
  //     console.log("Failed to fetch candidate", res);
  //   }
  // });
  const base_url = process.env.REACT_APP_SERVER_URL;

  const url = base_url + '/api/candidate/';
  const [data, setData] = useState([]);
  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = () => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        resp.map((row) => {
          row.referee = row.referee ? row.referee.email : 'Not Given';
          row.location = row.company ? row.company.location : 'Not Given';
          row.company = row.company ? row.company.name : 'Not Given';

          return row;
        });

        console.log('NAX');
        console.log(resp);

        setData(resp);
      });
  };

  const link = base_url + '/api/referee/';
  const [referee, setReferee] = useState([]);
  const [company, setCompany] = useState([]);
  useEffect(() => {
    getReferee();
    getCompanyList((res) => {
      console.log(res.data);
      setCompany(res.data);
    });
  }, []);

  const getReferee = async () => {
    await fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setReferee(resp));
  };

  const clientOptions = {};
  const statusOptions = {
    Present: 'Present',
    Inactive: 'Inactive',
  };
  const companyOptions = {};
  const locationOptions = {};

  referee.map((client) => {
    const { email } = client;
    clientOptions[email] = email;
  });

  company.map((client) => {
    const { name } = client;
    const { location } = client;
    companyOptions[name] = name;
    locationOptions[location] = location;
  });

  const columns = [
    { title: 'First Name', field: 'first_name' },
    { title: 'Last Name', field: 'last_name' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    { title: 'Work Rate', field: 'work_rate' },
    { title: 'Role', field: 'role' },
    {
      title: 'Work Duration Start',
      field: 'work_duration',
      type: 'date',
    },
    {
      title: 'Work Duration End',
      field: 'work_duration_end',
      type: 'date',
    },
    {
      title: 'Status',
      field: 'status',
      lookup: statusOptions,
    },
    { title: 'Referee', field: 'referee', lookup: clientOptions },
    { title: 'Company', field: 'company', lookup: companyOptions },
    { title: 'Location', field: 'location', editable: 'never' },
    {
      title: 'Date',
      field: 'date',
      type: 'date',
    },
  ];

  //   const options = {
  //   filterType: "checkbox",

  //   onRowsDelete: (rowsDeleted) => {
  //   const idsToDelete = rowsDeleted.data.map (item => item.dataIndex)

  //   // this.handleDeleteUser (idsToDelete)
  //     console.log (idsToDelete);
  // }
  //   // onRowsDelete:(e)=>{console.log(e.data)},
  // };

  return (
    <div className="StudentTable">
      {/* <MUIDataTable
        title={"Student List"}
        data={student}
        columns={columns}
        options={options}
      /> */}
      <div className="scroll">
        <MaterialTable
          title="Student List"
          data={data}
          icons={tableIcons}
          columns={columns}
          editable={{
            // onRowAdd: (newRow) => new Promise((resolve, reject) => {
            //   const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
            //   setTimeout(() => {
            //     setData(updatedRows)
            //     resolve()
            //   }, 2000)
            // }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                console.log(oldData.id);
                fetch(base_url + '/api/candidate' + '/' + oldData.id + '/', {
                  method: 'DELETE',
                  headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                })
                  .then((resp) => resp.json())
                  .then((resp) => {
                    getStudents();
                    toast.success('Deleted Successfully', {
                      position: toast.POSITION.bottom_right,
                    });
                    resolve();
                  });
                // const updatedRows = [...data]
                // updatedRows.splice(index, 1)
                // setTimeout(() => {
                //   setData(updatedRows)
                //   resolve()
                // }, 2000)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                //Backend call

                console.log(newData);

                fetch(base_url + '/api/candidate' + '/' + oldData.id + '/', {
                  method: 'PUT',
                  headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },

                  body: JSON.stringify(newData),
                })
                  .then((resp) => resp.json())
                  .then((resp) => {
                    if (resp.status === -1) {
                      getStudents();
                      toast.error(
                        'No two students can share the same work duration for same referee.',
                        {
                          position: toast.POSITION.bottom_right,
                        }
                      );
                      resolve();
                    } else {
                      getStudents();
                      toast.success('Updated Successfully', {
                        position: toast.POSITION.bottom_right,
                      });
                      resolve();
                    }
                  });
              }),
          }}
          options={{
            headerStyle: {
              backgroundColor: '#d8f0ff',
              fontWeight: 600,
            },
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            pageSize: 15,

            exportButton: true,
          }}
        />
        <ToastContainer bodyClassName="toastBody" />
      </div>
    </div>
  );
}
