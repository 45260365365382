import axios from "axios";

const base_url = process.env.REACT_APP_SERVER_URL;

const login = (data, callback) => {
  const url = base_url + "/api/users/token/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        apiResponse.token = response.data.access;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const signup = (data, callback) => {
  const url = base_url + "/api/users/user-register/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const addCompany = (data, callback) => {
  const url = base_url + "/api/company/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 201) {
        apiResponse.status = response.status;
        apiResponse.token = response.data.token;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const addStudentCompany = (data, callback) => {
  const url = base_url + "/api/company/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 201) {
        apiResponse.status = response.status;
        apiResponse.token = response.data.token;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getStudentCompany = (callback) => {
  const url = base_url + "/api/student_candidate/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const addReferee = (data, callback) => {
  const url = base_url + "/api/referee/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 201) {
        apiResponse.status = response.status;
        apiResponse.token = response.data.token;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const addCandidate = (data, callback) => {
  const url = base_url + "/api/candidate/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 201) {
        apiResponse.status = response.status;
        apiResponse.response = response.data.token;
        console.log("API Success", response);
      } else if (response.status === 400) {
        apiResponse.status = response.status;
        apiResponse.response = response.data.token;
        console.log("API Failed", response);
      } else {
        apiResponse.response = response.data.message;
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const addRecruitment = (data, callback) => {
  const url = base_url + "/api/recruitment/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      apiResponse.status = response.status;
      if (response.status === 200) {
        apiResponse.status = response.status;
        apiResponse.token = response.data.token;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getStudent = (callback) => {
  const url = base_url + "/api/candidate/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getJobSupport = (callback) => {
  const url =
    "https://stms.qatekinternal.com/job-support/partner-api/job-support-list/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getUser = (callback) => {
  const url = base_url + "/api/users/user_list/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getStudentList = (callback) => {
  const url = base_url + "/api/student_list/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getCompanyList = (callback) => {
  const url = base_url + "/api/company_list/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getRefereeList = (callback) => {
  const url = base_url + "/api/referee_list/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getReferee = (callback) => {
  const url = base_url + "/api/referee/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getRecruitment = (callback) => {
  const url = base_url + "/api/recruitment/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const resetPassword = (data, callback) => {
  const url = base_url + "/api/users/forgot_password/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    code: null,
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        apiResponse.code = response.data.code;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const approveRole = (data, callback) => {
  const url = base_url + "/api/users/approve_admin/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    code: null,
  };

  axios({
    method: "PUT",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        apiResponse.code = response.data.code;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const rejectRole = (data, callback) => {
  const url = base_url + "/api/users/approve_admin/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    code: null,
  };

  axios({
    method: "DELETE",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 410) {
        apiResponse.status = response.status;
        apiResponse.code = response.data.code;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const otpVerification = (data, callback) => {
  const url = base_url + "/api/users/check_forgot_password_otp/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    code: null,
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        apiResponse.code = response.data.code;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const passwordConfirmation = (data, callback) => {
  const url = base_url + "/api/users/reset_password/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    code: null,
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        apiResponse.code = response.data.code;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getRole = (data, callback) => {
  const url = base_url + "/api/users/user_profile/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

const getTotalUser = (callback) => {
  const url = base_url + "/api/total_user/";
  let apiResponse = {
    response: null,
    status: null,
    error: false,
    msg: "",
    token: "",
    data: {},
  };

  axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "json",
    responseEncoding: "utf8",
  })
    .then((response) => {
      if (response.status === 200) {
        apiResponse.status = response.status;
        console.log(response);
        apiResponse.data = response.data;
        console.log("API Success", response);
      }
    })
    .catch((error) => {
      console.log(error.message);
      apiResponse.status = error.response.status;
    })
    .finally(() => {
      callback(apiResponse);
    });
};

export {
  login,
  signup,
  addCompany,
  addReferee,
  addCandidate,
  addRecruitment,
  getStudent,
  getReferee,
  getRecruitment,
  resetPassword,
  otpVerification,
  passwordConfirmation,
  getUser,
  approveRole,
  rejectRole,
  getRole,
  getTotalUser,
  getJobSupport,
  addStudentCompany,
  getStudentCompany,
  getStudentList,
  getCompanyList,
  getRefereeList,
};
