// import { Email, Password } from "@mui/icons-material";
import "../Login/Login.css";
import { useState } from "react";
import { passwordConfirmation } from "../../api/api";

const frontend_url = process.env.FRONTEND_URL;

function Confirmation() {
  const [password, setPassword] = useState("");
  function Password() {
    var x = document.getElementById("yourPassword");
    var y = document.getElementById("hide1");
    var z = document.getElementById("hide2");

    if (x.type === "password") {
      x.type = "text";
      y.style.display = "block";
      z.style.display = "none";
    } else {
      x.type = "password";
      y.style.display = "none";
      z.style.display = "block";
    }
  }

  const formHandler = (e) => {
    e.preventDefault();
    const email = localStorage.getItem("email");
    const data = {
      email: email,
      password: password,
    };

    passwordConfirmation(data, (res) => {
      if (res.status === 200) {
        if (res.code === 200) {
          console.log("Password Found");
          window.location.replace("https://jrms.qatekinternal.com");
        } else {
          console.log("Password not found");
        }
      } else {
        console.log("Failed ", res);
      }
    });
  };
  return (
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div className="container">
        <div className="row">
          <div className="col">
            <div class="d-flex justify-content-center py-4 title">
              <h1>Job Reference Management System</h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="card mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    Choose a new password
                  </h5>
                  {/* <p className="text-center small">
                    Please enter your new password
                  </p> */}
                </div>
                <form
                  className="row g-3 needs-validation"
                  noValidate
                  onSubmit={formHandler}
                >
                  <div className="col-12">
                    <div className="input-group has-validation">
                      <input
                        type="password"
                        name="password"
                        placeholder="New password"
                        className="form-control"
                        id="yourPassword"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="eye input-group-text" onClick={Password}>
                        <i id="hide1" class="fas fa-eye"></i>
                        <i id="hide2" class="fas fa-eye-slash"></i>
                      </span>

                      <div className="invalid-feedback">
                        Please enter your new password.
                      </div>
                    </div>
                  </div>

                  {/* <div className="invalid-feedback">
                    Please enter your password!
                  </div> */}

                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 button"
                      type="submit"
                    >
                      Confirm
                    </button>
                  </div>

                  {/* <div className="col-12 text-center">
                    <p className="small mb-0">
                      <button type="button" class="btn btn-primary">
                        <a href="/Signup" className="button">
                          Create an account
                        </a>
                      </button>
                    </p>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Confirmation;
