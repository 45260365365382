import { React, useState, useEffect } from "react";
import "../StudentTable/StudentTable.css";
import { getStudentCompany } from "../../api/api";
// import Editbutton from "./Editbutton";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import MUIDataTable from "mui-datatables";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ToastContainer, toast } from "react-toastify";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function StudentCompanyTable() {
  // const [student, setStudent] = useState([]);

  // getStudent((res) => {
  //   if (student.length !== 0) return;

  //   if (res.status === 200) {
  //     console.log("Candidate fetched Successfully");

  //     console.log(res);
  //      setStudent(res.data)

  //   }

  //   else {
  //     console.log("Failed to fetch candidate", res);
  //   }
  // });
  const base_url = process.env.REACT_APP_SERVER_URL;
  const url = base_url + "/api/company/";

  const [studentCompany, setStudentCompany] = useState([]);
  useEffect(() => {
    getStudentCompanies();
  }, []);

  const getStudentCompanies = () => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setStudentCompany(resp));
  };

  const columns = [
    // { title: "First Name", field: "st_first_name" },
    // { title: "Last Name", field: "st_last_name" },
    { title: "Company", field: "name" },
    { title: "Location", field: "location" },
  ];

  //   const options = {
  //   filterType: "checkbox",

  //   onRowsDelete: (rowsDeleted) => {
  //   const idsToDelete = rowsDeleted.data.map (item => item.dataIndex)

  //   // this.handleDeleteUser (idsToDelete)
  //     console.log (idsToDelete);
  // }
  //   // onRowsDelete:(e)=>{console.log(e.data)},
  // };

  return (
    <div className="StudentTable">
      <MaterialTable
        title="Student's Company List"
        data={studentCompany}
        icons={tableIcons}
        columns={columns}
        editable={{
          // onRowAdd: (newRow) => new Promise((resolve, reject) => {
          //   const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
          //   setTimeout(() => {
          //     setData(updatedRows)
          //     resolve()
          //   }, 2000)
          // }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              console.log(oldData.id);
              fetch(base_url + "/api/company" + "/" + oldData.id + "/", {
                method: "DELETE",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  getStudentCompanies();
                  toast.success("Deleted Successfully", {
                    position: toast.POSITION.bottom_right,
                  });
                  resolve();
                });
              // const updatedRows = [...data]
              // updatedRows.splice(index, 1)
              // setTimeout(() => {
              //   setData(updatedRows)
              //   resolve()
              // }, 2000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              //Backend call
              fetch(base_url + "/api/company" + "/" + oldData.id + "/", {
                method: "PUT",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },

                body: JSON.stringify(newData),
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  getStudentCompanies();
                  toast.success("Updated Successfully", {
                    position: toast.POSITION.bottom_right,
                  });
                  resolve();
                });
            }),
        }}
        options={{
          headerStyle: {
            backgroundColor: "#d8f0ff",

            fontWeight: 600,
          },
          actionsColumnIndex: -1,
          addRowPosition: "first",
          pageSize: 15,

          exportButton: true,
        }}
      />
      <ToastContainer />
    </div>
  );
}
