import "./sidebar.css";
import {
  School,
  Group,
  Business,
  Person,
  AllInclusive,
  Settings,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@mui/icons-material";
// import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">
            <a href="/dashboard">Dashboard</a>
          </h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <a href="/Studentlist">
                <School className="sidebarIcon" /> Student List
              </a>
            </li>

            <li className="sidebarListItem">
              <a href="/Refereelist">
                <Group className="sidebarIcon" />
                Referee List
              </a>
            </li>
            <li className="sidebarListItem">
              <a href="/StudentCompanyList">
                <Business className="sidebarIcon" />
                Student's Company List
              </a>
            </li>

            <li className="sidebarListItem">
              <a href="/Companylist">
                <Business className="sidebarIcon" />
                Recruiting Company List
              </a>
            </li>
            <li className="sidebarListItem">
              <a href="/JobSupport">
                <Person className="sidebarIcon" />
                Job Support
              </a>
            </li>

            <li className="sidebarListItem">
              <a href="/Viewalllist">
                <AllInclusive className="sidebarIcon" />
                View All
              </a>
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Add Info</h3>
          <ul className="sidebarList">
            {/* <Link to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Users
              </li>
            </Link> */}
            {/* <Link to="/products" className="link">
              <li className="sidebarListItem">
                <Storefront className="sidebarIcon" />
                Products
              </li>
            </Link> */}
            <li className="sidebarListItem">
              <a className="add" href="/AddStudent">
                Add Student
              </a>
            </li>
            <li className="sidebarListItem">
              <a href="/AddReferee">Add Referee </a>
            </li>
            <li className="sidebarListItem">
              {" "}
              <a href="/AddStudentCompany">Add Student's Company </a>
            </li>
            <li className="sidebarListItem">
              {" "}
              <a href="/AddRecruitingCompany">Add Recruiting Company </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
