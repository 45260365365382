import { React, useState } from "react";
import "../StudentTable/StudentTable.css";
import { getStudent } from "../../api/api";
import MaterialTable from "material-table";
import tableIcons from "../tableIcon/tableIcon";

const columns = [
  { title: "Student's Name", field: "name" },
  { title: "Company", field: "company" },
  { title: "Location", field: "location" },
  { title: "Phone", field: "phone" },
  { title: "Email", field: "email" },
  { title: "Work Duration", field: "work_duration" },
  { title: "Designation", field: "role" },
  { title: "Referee's Name", field: "referee_name" },
  { title: "Referee's Phone", field: "referee_phone" },
  { title: "Referee's Email", field: "referee_email" },

  // "Email",
  // "Phone",
  // "Work Rate",
  // "Role",
  // "Work Duration",
  // "Referee",
  // "Company",
];

export default function ViewAllTable() {
  const [student, setStudent] = useState([]);

  getStudent((res) => {
    if (student.length !== 0) return;

    if (res.status === 200) {
      console.log("student fetched Successfully");

      console.log(res);

      const temp = res.data;
      temp.map((recruiter) => {
        recruiter.name = recruiter.first_name + " " + recruiter.last_name;
        recruiter.location = recruiter.company
          ? recruiter.company.location
          : "";

        recruiter.company = recruiter.company ? recruiter.company.name : "";

        recruiter.referee_name = recruiter.referee
          ? recruiter.referee.first_name + " " + recruiter.referee.last_name
          : "";
        recruiter.referee_email = recruiter.referee
          ? recruiter.referee.email
          : "";
        recruiter.referee_phone = recruiter.referee
          ? recruiter.referee.phone
          : "";

        return recruiter;
      });
      setStudent(temp);
      console.log("NAXX");
      console.log(temp);
    } else {
      console.log("Failed to fetch student", res);
    }
  });

  return (
    <div className="StudentTable">
      <MaterialTable
        title="View All"
        data={student}
        icons={tableIcons}
        columns={columns}
        // onRowAdd: (newRow) => new Promise((resolve, reject) => {
        //   const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
        //   setTimeout(() => {
        //     setData(updatedRows)
        //     resolve()
        //   }, 2000)
        // }),

        options={{
          headerStyle: {
            backgroundColor: "#d8f0ff",

            fontWeight: 600,
          },
          actionsColumnIndex: -1,
          addRowPosition: "first",
          pageSize: 15,

          exportButton: true,
        }}
      />
    </div>
  );
}
