import React from "react";
import Topbar from "../../Components/topbar/Topbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import CompanyTable from "../../Components/CompanyTable/CompanyTable";
import "../StudentList/StudentList.css";
import StudentCompanyTable from "../../Components/StudentCompany/StudentCompany";

export default function StudentCompanyList() {
  return (
    <div className="StudentList">
      <Topbar />
      <div className="SL">
        <Sidebar />
        <StudentCompanyTable />
      </div>
    </div>
  );
}
