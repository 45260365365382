import React from 'react'
import Topbar from "../../Components/topbar/Topbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import ViewAllTable from "../../Components/ViewAllTable/ViewAllTable";
import "../StudentList/StudentList.css";

export default function ViewAllList() {
  return (
     <div className="StudentList">
      <Topbar />
      <div className="SL">
        <Sidebar />
        <ViewAllTable/>
      </div>
    </div>
  )
}
