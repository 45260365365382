import { useState } from "react";
import { signup } from "../../api/api";
import logo from "../../assets/logo.png";

import { DropdownButton, Dropdown, Form } from "react-bootstrap";
// import {
//   CDropdown,
//   CDropdownToggle,
//   CDropdownItem,
//   CDropdownMenu,
//   CButton,
//   CDropdownDivider,
// } from "@coreui/react";
const frontend_url = process.env.FRONTEND_URL;

function Signup() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [admin, setAdmin] = useState(false);

  function Password(htmlClass) {
    var x = document.getElementById(htmlClass);

    if (htmlClass === "yourPassword1") {
      var y = document.getElementById("hide1");
      var z = document.getElementById("hide2");
    } else {
      var y = document.getElementById("hide3");
      var z = document.getElementById("hide4");
    }

    if (x.type === "password") {
      x.type = "text";
      y.style.display = "block";
      z.style.display = "none";
    } else {
      x.type = "password";
      y.style.display = "none";
      z.style.display = "block";
    }
  }

  const formHandler = (e) => {
    e.preventDefault();
    if (password !== password1) return;
    const data = {
      name: username,
      email: email,
      password: password,
      role: admin,
      is_admin: false,
    };
    signup(data, (response) => {
      console.log(response);
      if (response.status === 200) {
        window.location.replace("https://jrms.qatekinternal.com");
      } else {
        setUsername("");
        setPassword("");
        localStorage.clear();
        // setErrors(true);
      }
    });
  };
  return (
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div className="row justify-content-center">
          <div className="col">
            <div className="d-flex justify-content-center py-4 title">
              <img className="logostyle" src={logo} alt="logo" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div class="d-flex justify-content-center py-4 title">
              <h1>Job Reference Management System</h1>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div class="card mb-3">
              <div class="card-body">
                <div class="pt-4 pb-2">
                  <h5 class="card-title text-center pb-0 fs-4">
                    Create an Account
                  </h5>
                  <p class="text-center small">
                    Enter your personal details to create account
                  </p>
                </div>

                <form
                  class="row g-3 needs-validation"
                  novalidate
                  onSubmit={formHandler}
                >
                  <div class="col-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="First Name"
                      class="form-control"
                      id="yourName"
                      required
                    />
                    <div class="invalid-feedback">Please, enter your name!</div>
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="Last Name"
                      class="form-control"
                      id="yourName"
                      required
                    />
                    <div class="invalid-feedback">Please, enter your name!</div>
                  </div>

                  <div class="col-12">
                    <div class="input-group has-validation">
                      <input
                        type="text"
                        name="username"
                        placeholder="User Name"
                        class="form-control"
                        id="yourUsername"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <div class="invalid-feedback">
                        Please choose a username.
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      class="form-control"
                      id="yourEmail"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div class="invalid-feedback">
                      Please enter a valid Email adddress!
                    </div>
                  </div>

                  <div className="col-12">
                    <div class="input-group">
                      <input
                        type="password"
                        name="password"
                        placeholder=" Password"
                        className="form-control"
                        id="yourPassword1"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="eye input-group-text"
                        onClick={() => Password("yourPassword1")}
                      >
                        <i id="hide1" class="fas fa-eye"></i>
                        <i id="hide2" class="fas fa-eye-slash"></i>
                      </span>
                    </div>

                    <div className="invalid-feedback">
                      Please enter your password!
                    </div>
                  </div>
                  <div className="col-12">
                    <div class="input-group">
                      <input
                        type="password"
                        name="password"
                        placeholder="Confirm Password"
                        className="form-control"
                        id="yourPassword2"
                        onChange={(e) => setPassword1(e.target.value)}
                        required
                      />
                      <span
                        className="eye input-group-text"
                        onClick={() => Password("yourPassword2")}
                      >
                        <i id="hide3" class="fas fa-eye"></i>
                        <i id="hide4" class="fas fa-eye-slash"></i>
                      </span>
                    </div>

                    <div className="invalid-feedback">
                      Please enter your password!
                    </div>
                  </div>

                  <div class="col-12">
                    <Form.Select
                      aria-label="Regular User"
                      onChange={(e) => {
                        setAdmin(e.target.value);
                      }}
                    >
                      <option value="">Select User Role</option>
                      <option value="Admin">Admin</option>
                      <option value="Regular User">Regular User</option>
                    </Form.Select>
                  </div>

                  {/* <div className="col-12">
                    <CDropdown variant="btn-group">
                      <CButton color="secondary" size="lg">
                        Large split button
                      </CButton>
                      <CDropdownToggle color="secondary" size="lg" split />
                      <CDropdownMenu>
                        <CDropdownItem href="#">Action</CDropdownItem>
                        <CDropdownItem href="#">Another action</CDropdownItem>
                        <CDropdownItem href="#">
                          Something else here
                        </CDropdownItem>
                        <CDropdownDivider />
                        <CDropdownItem href="#">Separated link</CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </div> */}

                  <div class="col-12">
                    <button class="btn btn-primary w-100" type="submit">
                      SIGNUP
                    </button>
                  </div>
                  <div class="col-12">
                    <p class="small mb-0">
                      Already have an account?{" "}
                      <a className="resetPassword" href="/">
                        Log in
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
