import { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import { approveRole, rejectRole } from "../../api/api";

const frontend_url = process.env.FRONTEND_URL;

function CustomCell(props) {
  const [st, setSt] = useState("");
  const handleChange = (e) => {
    setSt(e.target.value);
  };

  const updateRole = (email, status) => {
    const data = {
      email: email,
      status: status,
    };

    console.log(typeof st);

    if (st === "true") {
      approveRole(data, (response) => {
        if (response.status === 200) {
          console.log(response);
          window.location.replace("https://jrms.qatekinternal.com/Approval");
        }
      });
    } else {
      rejectRole(data, (response) => {
        if (response.status === 410) {
          console.log(response);
          window.location.replace("https://jrms.qatekinternal.com/Approval");
        }
      });
    }
  };

  if (props.status) {
    return (
      <>
        <TableCell>
          <select onChange={handleChange}>
            <option value="true">Approve</option>
            <option value="false">Reject</option>
          </select>
        </TableCell>
        <TableCell>
          <button onClick={() => updateRole(props.email, st)}>Save</button>
        </TableCell>
      </>
    );
  } else {
    return (
      <>
        <TableCell>
          <select onChange={handleChange}>
            <option value="true">Reject</option>
            <option value="false">Approve</option>
          </select>
        </TableCell>
        <TableCell>
          <button onClick={() => updateRole(props.email, st)}>Save</button>
        </TableCell>
      </>
    );
  }
}

export default CustomCell;
