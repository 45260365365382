import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import PrivateRoute from "./PrivateRoute";

import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import StudentList from "./Pages/StudentList/StudentList";
import RefereeList from "./Pages/RefereeList/RefereeList";
import CompanyList from "./Pages/CompanyList/CompanyList";
import ViewAllList from "./Pages/ViewAllList/ViewAllList";
import Referee from "./Components/AddReferee/Referee";
import Student from "./Components/AddStudent/Student";
import Company from "./Components/Company/Company";
import RecruitingCompany from "./Components/RecruitingCompany/RecruitingCompany";
import Mail from "./Components/ResetPassword/Mail";
import Otp from "./Components/ResetPassword/Otp";
import Confirmation from "./Components/ResetPassword/Confirmation";
import Approval from "./Components/UserApproval/UserApproval";
import Homepage from "./Components/Homepage/Home";
import JobSupport from "./Components/JobSupport/JobSupport";
import StudentCompanyList from "./Pages/StudentCompanyList/CompanyList";

function App() {
  return (
    <Router>
      <Routes>
        {/* Public */}
        <Route exact path="/" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/reset-password" element={<Mail />} />
        <Route path="/otp-verification" element={<Otp />} />
        <Route path="/password-confirmation" element={<Confirmation />} />
        <Route path="/approval" element={<Approval />} />

        {/* Private */}

        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <Homepage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/StudentList"
          element={
            <PrivateRoute>
              <StudentList />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/StudentCompanyList"
          element={
            <PrivateRoute>
              <StudentCompanyList />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/JobSupport"
          element={
            <PrivateRoute>
              <JobSupport />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/Refereelist"
          element={
            <PrivateRoute>
              <RefereeList />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/AddReferee"
          element={
            <PrivateRoute>
              <Referee />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/AddStudent"
          element={
            <PrivateRoute>
              <Student />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/AddStudentCompany"
          element={
            <PrivateRoute>
              <Company />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/AddRecruitingCompany"
          element={
            <PrivateRoute>
              <RecruitingCompany />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/CompanyList"
          element={
            <PrivateRoute>
              <CompanyList />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/JobSupport"
          element={
            <PrivateRoute>
              <JobSupport />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/ViewAllList"
          element={
            <PrivateRoute>
              <ViewAllList />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Login />} />
    //     <Route path="/Signup" element={<Signup />} />
    //     <Route path="/Studentlist" element={<StudentList />} />
    //     <PrivateRoute exact path="/Studentlist" component={StudentList} />
    //     <Route path="/Refereelist" element={<RefereeList />} />
    //     <Route path="/AddReferee" element={<Referee />} />
    //     <Route path="/AddStudent" element={<Student />} />
    //     <Route path="/AddCompany" element={<Company />} />
    //     <Route path="/Companylist" element={<CompanyList />} />
    //     <Route path="/Viewalllist" element={<ViewAllList />} />
    //   </Routes>
    // </Router>
  );
}

export default App;
