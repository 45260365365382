import React from 'react'
import Topbar from "../../Components/topbar/Topbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import RefereeTable from "../../Components/RefereeTable/RefereeTable";
import "../StudentList/StudentList.css";

export default function RefereeList() {
  return (
     <div className="StudentList">
      <Topbar />
      <div className="SL">
        <Sidebar />
        <RefereeTable/>
      </div>
    </div>
  )
}
