import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import MaterialTable from 'material-table';

import { forwardRef, useEffect, useState } from 'react';

import { getJobSupport, addCandidate } from '../../api/api';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { ToastContainer, toast } from 'react-toastify';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const JobSupport = () => {
  const [studentList, setStudentList] = useState([]);
  useEffect(() => {
    getJobSupport((res) => {
      const studentList = res.data;
      const jobList = [];
      studentList.map((student) => {
        var temp = {};
        console.log(student);

        if (student.student_record !== null) {
          var middle_name =
            student.student_record.middle_name !== null
              ? student.student_record.middle_name + ' '
              : '';

          temp.name = student.student_record.first_name
            ? student.student_record.first_name +
              middle_name +
              student.student_record.last_name
            : 'Not Given';

          temp.email = student.student_record.email
            ? student.student_record.email
            : 'Not Given';
          temp.phone = student.student_record.phone_number
            ? student.student_record.phone_number
            : 'Not Given';

          temp.street = student.student_address.street_address
            ? student.student_address.street_address
            : 'Not Given';

          temp.city = student.student_address.city
            ? student.student_address.city
            : 'Not Given';
          temp.state = student.student_address.state
            ? student.student_address.state
            : 'Not Given';
          temp.country = student.student_address.country
            ? student.student_address.country
            : 'Not Given';
          temp.candidate_status = student.candidate_status
            ? student.candidate_status
            : 'Not Given';
        } else {
          temp.name = student.name;
          temp.email = student.email;
          temp.phone = student.phone;
          temp.candidate_status = student.candidate_status;
          temp.street = student.job_support_record_address.street_address
            ? student.job_support_record_address.street_address
            : 'Not Given';
          temp.city = student.job_support_record_address.city
            ? student.job_support_record_address.city
            : 'Not Given';
          temp.state = student.job_support_record_address.state
            ? student.job_support_record_address.state
            : 'Not Given';
          temp.country = student.job_support_record_address.country
            ? student.job_support_record_address.country
            : 'Not Given';
        }

        temp.referred = student.referred_by ? student.referred_by : 'Not Given';
        temp.job_support = student.job_support_area
          ? student.job_support_area
          : 'Not Given';

        jobList.push(temp);
      });

      setStudentList(jobList);
    });
  }, []);

  const columns = [
    { title: 'Name', field: 'name' },
    {
      title: 'Job Status',
      field: 'candidate_status',
      render: (rowData) => {
        return rowData.candidate_status == 'Job Market' ? (
          <p className="job-market-status">{rowData.candidate_status}</p>
        ) : (
          <p className="job-holder-status">{rowData.candidate_status}</p>
        );
      },
    },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    { title: 'Street', field: 'street' },
    { title: 'City', field: 'city' },
    { title: 'State', field: 'state' },
    { title: 'Country', field: 'country' },
    { title: 'Referred By', field: 'referred' },
    { title: 'Job Support Area', field: 'job_support' },
    { title: 'Job Support Area', field: 'job_support' },
    {
      title: 'Action',
      field: 'internal_action',
      editable: false,
      render: (rowData) =>
        rowData && (
          <IconButton
            color="secondary"
            onClick={() => {
              console.log(rowData);
              var temp_name = rowData['name'].split(' ');

              const data = {
                first_name: temp_name.slice(0, -1).join(' '),
                last_name: temp_name.slice(-1)[0],
                phone: rowData['phone'],
                email: rowData['email'],
                work_duration: 'Not Given',
                work_rate: 'Not Given',
                role: rowData['job_support'],
                company: 'Not Given',
                referee: 'Not Given',
                date: 'Not Given',
              };

              addCandidate(data, (res) => {
                if (res.status === 201) {
                  console.log('Student added to student list successfully');

                  toast.success('Student added successfully', {
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                } else {
                  console.log(res);
                  toast.error('Failed to add student', {
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  console.log('Failed to add student', res);
                }
              });
            }}
          >
            <AddBox />
          </IconButton>
        ),
    },
  ];

  return (
    <>
      <div className="StudentList">
        <Topbar />
        <div className="SL">
          <Sidebar />
          <div className="StudentTable">
            <MaterialTable
              style={{}}
              title="Job Support"
              icons={tableIcons}
              columns={columns}
              data={studentList}
              options={{
                headerStyle: {
                  backgroundColor: '#d8f0ff',
                  fontWeight: 600,
                },

                pageSize: 15,
              }}
            />
            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSupport;
