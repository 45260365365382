import { useState, useEffect } from "react";

import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { getTotalUser } from "../../api/api";

import "./Home.css";

function Homepage() {
  const [totalUser, setTotalUser] = useState({});

  useEffect(() => {
    getTotalUser((res) => {
      const temp = res.data;
      console.log("TEST" + temp);
      setTotalUser(temp);
    });
  }, []);

  return (
    <>
      <Topbar />
      <div className="SL">
        <Sidebar />
        <section className="StudentTable">
          <div className="analytics section-border-radius">
            <h3 className="section-header">Dashboard</h3>
            <div className="list-analytics">
              <div className="analytic a">
                <span className="icon">
                  <i className="fas fa-users"></i>
                </span>
                <h3 className="num">{totalUser["student_company"]}</h3>
                <p className="desc">Total Student's Company</p>
              </div>
              <div className="analytic b">
                <span className="icon">
                  <i className="fas fa-fingerprint"></i>
                </span>
                <h3 className="num">{totalUser["referee"]}</h3>
                <p className="desc">Total Referee</p>
              </div>
              <div className="analytic c">
                <span className="icon">
                  <i className="fas fa-chalkboard-teacher"></i>
                </span>
                <h3 className="num">{totalUser["recruitment"]}</h3>
                <p className="desc">Total Recruting Company</p>
              </div>
              <div className="analytic d">
                <span className="icon">
                  <i className="fas fa-hand-pointer"></i>
                </span>
                <h3 className="num">{totalUser["candidate"]}</h3>
                <p className="desc">Total Student</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Homepage;
