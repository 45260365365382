import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { addCandidate, getCompanyList, getRefereeList } from "../../api/api";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Discard } from "../DiscardMsg/Discard";
import { useNavigatingAway } from "../../hooks/useNavigatingAway";
const frontend_url = process.env.FRONTEND_URL;

function Student() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [workRate, setWorkRate] = useState("");
  const [role, setRole] = useState("");
  const [workDuration, setWorkDuration] = useState("");
  const [company, setCompany] = useState("");
  const [referee, setReferee] = useState("");
  const [date, setDate] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [refereeList, setRefereeList] = useState([]);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  useEffect(() => {
    getCompanyList((res) => {
      console.log(res.data);
      setCompanyList(res.data);
    });

    getRefereeList((res) => {
      console.log(res.data);
      setRefereeList(res.data);
    });
  }, []);

  const formHandler = (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
      work_duration: workDuration,
      work_rate: workRate,
      role: role,
      company: company,
      referee: referee,
      date: date,
    };

    addCandidate(data, (res) => {
      if (res.status === 201) {
        console.log("Candidate Created Successfully");

        toast.success("Student Created Successfully, Redirecting...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setTimeout(() => {
          window.location.replace("https://jrms.qatekinternal.com/Refereelist");
        }, 3000);
        window.location.replace("https://jrms.qatekinternal.com/StudentList");
      } else if (res.status === 400) {
        console.log("Candidate Failed to Create");

        toast.success(
          "No two students can share the same work duration for same referee.",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        setTimeout(() => {
          window.location.replace("https://jrms.qatekinternal.com/Refereelist");
        }, 3000);
        window.location.replace("https://jrms.qatekinternal.com/StudentList");
      } else {
        console.log(res);
        toast.error("Failed to create student", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        console.log("Failed to create candidate", res);
      }
    });
  };

  return (
    <>
      <Topbar />
      <div className="SL">
        <Discard
          showDialog={showDialogLeavingPage}
          setShowDialog={setCanShowDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Sidebar />
        <div className="StudentTable">
          <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div className="row">
                <div className="col">
                  <div class="d-flex justify-content-center py-4 title">
                    <h1>Add Student</h1>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="pt-4 pb-2"></div>

                      <form
                        class="row g-3 needs-validation"
                        novalidate
                        onSubmit={formHandler}
                      >
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Student First Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter student name!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Student Last Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setLastName(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter student name!
                          </div>
                        </div>

                        <div class="col-6">
                          <input
                            type="phone"
                            name="name"
                            placeholder="Phone Number"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setPhone(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter Student Phone Number!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="email"
                            name="name"
                            placeholder="Student's Email"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter Student Email Address!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Work Duration"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setWorkDuration(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter Student Work Duration!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Work Rate"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setWorkRate(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter Student Work Rate!
                          </div>
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Role"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setRole(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter your role!
                          </div>
                        </div>

                        <div class="col-6">
                          <input
                            type="text"
                            name="date"
                            placeholder="Date"
                            class="form-control"
                            id="yourDate"
                            onChange={(e) => {
                              setDate(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter date!
                          </div>
                        </div>

                        <div className="col-6">
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            onChange={(event, newValue) => {
                              setReferee(newValue);
                            }}
                            options={refereeList.map((option) => option.email)}
                            renderInput={(params) => (
                              <TextField
                                onChange={(e) => {
                                  setReferee(e.target.value);
                                  if (e.target.value !== "")
                                    setCanShowDialogLeavingPage(true);
                                  else setCanShowDialogLeavingPage(false);
                                }}
                                {...params}
                                label="Referee's Email"
                              />
                            )}
                          />
                        </div>

                        <div class="col-6">
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            onChange={(event, newValue) => {
                              setCompany(newValue);
                            }}
                            options={companyList.map((option) => option.name)}
                            renderInput={(params) => (
                              <TextField
                                onChange={(e) => {
                                  setCompany(e.target.value);
                                  if (e.target.value !== "")
                                    setCanShowDialogLeavingPage(true);
                                  else setCanShowDialogLeavingPage(false);
                                }}
                                {...params}
                                label="Company"
                              />
                            )}
                          />
                        </div>

                        <div class="col-12 d-flex justify-content-center">
                          <ToastContainer />
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                          <button
                            class="btn btn-primary 
                       "
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Student;
