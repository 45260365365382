import { useState } from "react";
import { addStudentCompany } from "../../api/api";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
// import Discard from "../DiscardMsg/Discard";
import { Discard } from "../DiscardMsg/Discard";
import { useNavigatingAway } from "../../hooks/useNavigatingAway";

const frontend_url = process.env.FRONTEND_URL;

function Company() {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  // const [Prompt, setIsDirty, setPristine] = Discard();

  const formHandler = (e) => {
    e.preventDefault();
    const data = {
      // st_first_name: firstName,
      // st_last_name: lastName,
      name: name,
      location: location,
    };

    addStudentCompany(data, (res) => {
      console.log(res);
      if (res.status === 201) {
        console.log("Student Company Created Successfully");
        toast.success("Student Company Created Successfully, Redirecting...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        setCanShowDialogLeavingPage(false);

        setTimeout(() => {
          window.location.replace(
            "https://jrms.qatekinternal.com/StudentCompanyList"
          );
        }, 3000);
      } else {
        console.log("Failed to create student company", res);
        toast.error("Failed to create student company", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };

  return (
    <>
      <Topbar />
      <div className="SL">
        <Discard
          showDialog={showDialogLeavingPage}
          setShowDialog={setCanShowDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Sidebar />

        <div className="StudentTable">
          <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div className="row">
                <div className="col">
                  <div class="d-flex justify-content-center py-4 title">
                    <h1>Add Student's Company</h1>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="pt-4 pb-2"></div>

                      <form
                        class="row g-3 needs-validation"
                        novalidate
                        onSubmit={formHandler}
                      >
                        {/* <div class="col-12">
                          <input
                            type="text"
                            name="first_name"
                            placeholder="Student's First Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter First name!
                          </div>
                        </div>

                        <div class="col-12">
                          <input
                            type="text"
                            name="last_name"
                            placeholder="Student's Last Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter Last name!
                          </div>
                        </div> */}

                        <div class="col-12">
                          <input
                            type="text"
                            name="name"
                            placeholder="Company's Name"
                            class="form-control"
                            id="yourName"
                            onChange={(e) => {
                              setName(e.target.value);
                              if (e.target.value !== "")
                                setCanShowDialogLeavingPage(true);
                              else setCanShowDialogLeavingPage(false);
                            }}
                            required
                          />
                          <div class="invalid-feedback">
                            Please, enter Company name!
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="input-group has-validation">
                            <input
                              type="text"
                              name="username"
                              placeholder="Location"
                              class="form-control"
                              onChange={(e) => {
                                setLocation(e.target.value);
                                if (e.target.value !== "")
                                  setCanShowDialogLeavingPage(true);
                                else setCanShowDialogLeavingPage(false);
                              }}
                              id="yourUsername"
                              required
                            />
                            <div class="invalid-feedback">
                              Please enter Location.
                            </div>
                          </div>
                        </div>

                        <div class="col-12 d-flex justify-content-center">
                          <ToastContainer />
                        </div>

                        <div class="col-12 d-flex justify-content-center">
                          <button class="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Company;
