import { React, useState, useEffect } from "react";
import "../StudentTable/StudentTable.css";
import { getCompanyList, getStudent } from "../../api/api";
// import Editbutton from "./Editbutton";
import { ToastContainer, toast } from "react-toastify";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import MUIDataTable from "mui-datatables";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import tableIcons from "../tableIcon/tableIcon";

export default function RefereeTable() {
  // const [student, setStudent] = useState([]);

  // getStudent((res) => {
  //   if (student.length !== 0) return;

  //   if (res.status === 200) {
  //     console.log("Candidate fetched Successfully");

  //     console.log(res);
  //      setStudent(res.data)

  //   }

  //   else {
  //     console.log("Failed to fetch candidate", res);
  //   }
  // });
  const base_url = process.env.REACT_APP_SERVER_URL;
  const url = base_url + "/api/referee/";
  const [data, setData] = useState([]);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = () => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);

        resp.map((row) => (row.company = row.company.name));

        console.log(resp);

        setData(resp);
      });
  };

  const link = base_url + "/api/company/";
  const [referee, setReferee] = useState([]);
  useEffect(() => {
    getReferee();
    getCompanyList((res) => {
      console.log(res.data);
      setCompany(res.data);
    });
  }, []);

  const getReferee = () => {
    fetch(link, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setReferee(resp));
  };
  const companyOptions = {};

  company.map((client) => {
    const { name } = client;
    companyOptions[name] = name;
  });

  const columns = [
    { title: "Referee's First Name", field: "first_name", editable: false },
    { title: "Referee's Last Name", field: "last_name" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phone" },
    { title: "Work Duration", field: "work_duration" },
    { title: "Company", field: "company", lookup: companyOptions },
  ];

  //   const options = {
  //   filterType: "checkbox",

  //   onRowsDelete: (rowsDeleted) => {
  //   const idsToDelete = rowsDeleted.data.map (item => item.dataIndex)

  //   // this.handleDeleteUser (idsToDelete)
  //     console.log (idsToDelete);
  // }
  //   // onRowsDelete:(e)=>{console.log(e.data)},
  // };

  return (
    <div className="StudentTable">
      {/* <MUIDataTable
        title={"Student List"}
        data={student}
        columns={columns}
        options={options}
      /> */}
      <MaterialTable
        title="Referee List"
        data={data}
        icons={tableIcons}
        columns={columns}
        editable={{
          // onRowAdd: (newRow) => new Promise((resolve, reject) => {
          //   const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
          //   setTimeout(() => {
          //     setData(updatedRows)
          //     resolve()
          //   }, 2000)
          // }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              console.log(oldData.id);
              fetch(base_url + "/api/referee" + "/" + oldData.id + "/", {
                method: "DELETE",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  getStudents();
                  toast.success("Deleted Successfully", {
                    position: toast.POSITION.bottom_right,
                  });
                  resolve();
                });
              // const updatedRows = [...data]
              // updatedRows.splice(index, 1)
              // setTimeout(() => {
              //   setData(updatedRows)
              //   resolve()
              // }, 2000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              //Backend call
              fetch(base_url + "/api/referee" + "/" + oldData.id + "/", {
                method: "PUT",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },

                body: JSON.stringify(newData),
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  getStudents();
                  toast.success("Updated Successfully", {
                    position: toast.POSITION.bottom_right,
                  });
                  resolve();
                });
            }),
        }}
        options={{
          headerStyle: {
            backgroundColor: "#d8f0ff",

            fontWeight: 600,
          },
          actionsColumnIndex: -1,
          addRowPosition: "first",
          pageSize: 15,

          exportButton: true,
        }}
      />
      <ToastContainer />
    </div>
  );
}
