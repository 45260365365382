import { React, useEffect, useState } from "react";
import { getRole } from "../../api/api";
import "./topbar.css";
import logo from "../../assets/logo.png";
import { NotificationsNone, Language, Settings } from "@mui/icons-material";

const frontend_url = process.env.FRONTEND_URL;

export default function Topbar() {
  const [role, setRole] = useState("");
  const logoutHandler = () => {
    localStorage.clear();
    setRole("");
    window.location.replace("https://jrms.qatekinternal.com");
  };

  useEffect(() => {
    const temp = {
      email: localStorage.getItem("email"),
    };
    getRole(temp, (response) => {
      if (response.data.role === "Admin") {
        localStorage.setItem("role", "Admin");
      } else {
        localStorage.setItem("role", "user");
      }
    });
    setRole(localStorage.getItem("role"));
  }, []);

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <a href="/dashboard">
          <img className="img" src={logo} alt="logo" />
        </a>

        <div className="">
          <a href="/Studentlist">
            <span className="logo">JRMS</span>
          </a>
        </div>
        <div className="topRight">
          {role === "Admin" ? (
            <>
              <a href="/Approval">
                <span className="user">User Approval</span>
              </a>
              <span className="user">Admin</span>
            </>
          ) : (
            <span className="user">User</span>
          )}
          <button
            className=" btn btn-primary logout"
            onClick={() => logoutHandler()}
          >
            Logout
          </button>
          {/* <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Settings />
          </div>
          <img src="https://images.pexels.com/photos/1526814/pexels-photo-1526814.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" className="topAvatar" /> */}
        </div>
      </div>
    </div>
  );
}
